import { FormGroup, Form, FormLabel, FormControl } from 'react-bootstrap';
import { BannerContainer, ConfirmButton, FormContainer } from './WMSAuth.styles';
import React, { useEffect, useState } from 'react';
import { LoadingAnimation } from '../ResetPassword/ResetPassword.styles';
import { authorizeOnGateway } from '../../apis/ldapManager';
import './WMSAuth.css';
import { useParams, useSearchParams } from 'react-router-dom';
import Notification from '../../components/Notification/Notification';

const GROUP_QUERY_KEY = 'groupNames';

export const WMSAuth = () => {
    const { target } = useParams();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [groups, setGroups] = useState([]);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        try {
            const groupsString = searchParams.getAll(GROUP_QUERY_KEY);
            const parsedGroups = JSON.parse(groupsString.join(''));
            setGroups(parsedGroups);
        } catch {
            console.error('failed to parse groups authorized to access the target');
        }
    }, [searchParams]);

    const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setLoginError('');
        setIsWaiting(true);
        const response = await authorizeOnGateway(username, password, groups);
        if (response.error) {
            setLoginError(response.error.toString());
            setIsWaiting(false);
        } else {
            console.log(response);
            setIsWaiting(false);
            if (target) {
                window.location.href = `http://${target}`;
            }
        }
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    return (
        <>
            <Notification message={loginError} setMessage={setLoginError} />
            <FormContainer>
                <BannerContainer>WMS Authentication</BannerContainer>
                <Form className="form">
                    <FormGroup className="formGroup">
                        <FormLabel>Username</FormLabel>
                        <FormControl
                            placeholder="Enter password"
                            className="formInput"
                            onChange={handleUsernameChange}
                        />
                    </FormGroup>
                    <FormGroup className="formGroup">
                        <FormLabel>Password</FormLabel>
                        <FormControl
                            type="password"
                            placeholder="Confirm password"
                            className="formInput"
                            onChange={handlePasswordChange}
                        />
                    </FormGroup>

                    <ConfirmButton onClick={handleSubmit}>Login</ConfirmButton>
                    {isWaiting && <LoadingAnimation />}
                </Form>
            </FormContainer>
        </>
    );
};

export default WMSAuth;
